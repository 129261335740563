/**
 * A Typescript library which contains shared logic for all of my webserver projects.
 * @module
 */

/**
 * Publishes a highly-opinionated custom analytics event to the server.
 *
 * @example How to use:
 * ```ts
 * import { scitylana } from "@todd/webserver-base";
 *
 * document.addEventListener("DOMContentLoaded", scitylana);
 * ```
 */
export async function scitylana(e: Event): Promise<void> {
  e.preventDefault();

  const response: Response = await fetch("/api/v1/scitylana", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: new URLSearchParams({
      "user_agent": navigator.userAgent,
      "url": window.location.href,
      "referrer": document.referrer,
      "screen_width": window.innerWidth.toString(),
    }),
  });
  if (!response.ok) {
    console.error("Failed scitylana: ", response);
  }
}

// denoCacheMetadata={"headers":{"x-robots-tag":"noindex","access-control-expose-headers":"*","x-goog-stored-content-encoding":"identity","x-jsr-cache-status":"miss","via":"1.1 google","cross-origin-resource-policy":"cross-origin","accept-ranges":"bytes","etag":"\"9fce94f15a8e0cfd284efe8c55bacac2\"","expires":"Fri, 28 Nov 2025 03:34:02 GMT","access-control-allow-origin":"*","last-modified":"Fri, 01 Mar 2024 08:10:51 GMT","x-guploader-uploadid":"AFiumC77MVBFXd84nhRL0Ckyj3mxpUJgBdWIwo73A5sRUNlHIagGbMj96enWI-Y6tJhndNO_Z6FxDUzdfkI210k","x-goog-metageneration":"1","cache-control":"public, max-age=31536000, immutable","alt-svc":"h3=\":443\"; ma=2592000,h3-29=\":443\"; ma=2592000","content-type":"text/typescript","x-jsr-cache-id":"PHX-b8c3dbb1","content-length":"897","date":"Thu, 28 Nov 2024 03:34:02 GMT","x-content-type-options":"nosniff","x-goog-hash":"crc32c=0phhaQ==,md5=n86U8VqODP0oTv6MVbrKwg==","x-goog-storage-class":"STANDARD","x-goog-stored-content-length":"897","x-goog-generation":"1709280651748058","server":"UploadServer","content-security-policy":"default-src 'none'; script-src 'none'; style-src 'none'; img-src 'none'; font-src 'none'; connect-src 'none'; frame-src 'none'; object-src 'none'; frame-ancestors 'none'; sandbox; form-action 'none';"},"url":"https://jsr.io/@todd/webserver-base/0.0.1/ui/static/script/mod.ts","time":1732764842}